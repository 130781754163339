import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Utils
import { SECTION_MARGIN, SECTION_TOP_PADDING } from '../../utils/constants'
import { getUrlToShare } from '../../utils/commons'
import { getBreadcrumbSchema } from '../../utils/blog'
// Types
import { isImageBlock, PostPreviewWithCategoriesProps, PostProps, PostUtilsProps } from '../../types/blog'
import { PageContextProps } from '../../types/pages'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Layout, Seo } from '../../components/commons'
import { Flex, Box, Heading, Text, TextProps } from '../../atoms'
import PostHero from '../../components/blog/PostHero'
import PostSide from '../../components/blog/PostSide'
import PostShare from '../../components/blog/PostShare'
import styled from 'styled-components'
import { AuthorCard, PostPreview } from '../../components/blog/commons'
import { StructuredTextContent } from '../../components/commons/StructuredText/StructuredTextContent'
import StructuredTextSummary from '../../components/commons/StructuredTextSummary/StructuredTextSummary'
import { useLocale } from '../../hooks/useLocale'

interface PostQuery {
  post: PostProps
  utils: PostUtilsProps
  relatedPosts?: {
    nodes: PostPreviewWithCategoriesProps[]
  }
}

const Post: React.FC<PageProps<PostQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { post, utils, relatedPosts } = data

  const relatedPostList = relatedPosts?.nodes || post.relatedPosts
  // Structured Data
  const postImageBlocks: string[] = post.content?.blocks!.filter(isImageBlock).map((b) => b.asset.url)
  const postImages: string[] = [post.thumbnail?.url!, ...postImageBlocks]

  const PostSDataSchema = {
    pageType: 'NewsArticle',
    headline: post.title,
    image: postImages,
  }

  const breadcrumbSchema = getBreadcrumbSchema(pageContext.locale, post)

  return (
    <Layout pageId={pageContext.id} locale={pageContext.locale} allSlugLocales={pageContext.slugLocales}>
      <Seo
        slug={post.slug}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={post.meta}
        content={
          post.seo || {
            title: post.title,
            description: null,
            twitterCard: null,
            image: null,
          }
        }
        SDSchema={PostSDataSchema}
        additionalSDSchema={breadcrumbSchema ? [breadcrumbSchema] : []}
      />

      <Box pt={SECTION_TOP_PADDING}>
        <Grid>
          <Row>
            <Col xs={12} md={8}>
              <article>
                {/* HERO */}
                <PostHero {...post} />
                {/* CONTENT */}
                {!!post.content.value && <StructuredTextContent content={post.content} />}
              </article>

              {post.authors.length > 0 && (
                <Box mt={[6, 7, 8]}>
                  <Text fontWeight={'bold'}>{utils.aboutAuthors}</Text>

                  <Box mt={4}>
                    {post.authors.map((author, idx) => (
                      <Box key={author.id} mb={idx + 1 < post.authors.length ? 4 : 0}>
                        <AuthorCard author={author} />
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Col>
            <Col xs={false} md={4}>
              <Flex flexDirection={'column'} alignItems={'stretch'} justifyContent={'space-between'} height={'100%'}>
                <Box flex={1} pb={32}>
                  <Box position={'sticky'} top={'calc(90px + var(--banner-height))'}>
                    <StructuredTextSummary tableOfContentLabel={utils.tableOfContentLabel} {...post.content} />
                    <Box mt={24}>
                      <PostShare label={utils.shareLabel} urlToShare={getUrlToShare()} />
                    </Box>
                  </Box>
                </Box>
                {utils.showPush && (
                  <Box>
                    <PostSide headline={utils.headline} button={utils.button} />
                  </Box>
                )}
              </Flex>
            </Col>
          </Row>

          {relatedPostList.length > 0 && (
            <Box as={'section'} my={SECTION_MARGIN}>
              <Row>
                <Col xs={12}>
                  <Heading as={'h2'} type={'heading2'}>
                    {post.relatedLabel}
                  </Heading>
                </Col>
              </Row>
              <Box mt={[6, 7, 8]}>
                <Row>
                  {relatedPostList.map((rp) => (
                    <Col key={rp.id} xs={12} sm={6} md={4}>
                      <Box mb={[6, 7, 0]}>
                        <PostPreview data={rp} />
                      </Box>
                    </Col>
                  ))}
                </Row>
              </Box>
            </Box>
          )}
        </Grid>
      </Box>
    </Layout>
  )
}

export const LinkText: React.FC<TextProps & { as?: React.ElementType }> = styled(Text)<
  TextProps & { as?: React.ElementType }
>`
  text-decoration-color: ${({ theme }) => theme.colors.violetLight};
  text-decoration-thickness: 1px;

  &:hover {
    color: ${({ theme }) => theme.colors.darkBlueShade};
    text-decoration-color: ${({ theme }) => theme.colors.darkBlueShade};
  }
`

export const query = graphql`
  query PostQuery($id: String!, $locale: String!, $hasCustomRelatedPosts: Boolean!) {
    post: datoCmsPost(locale: $locale, id: { eq: $id }) {
      ...postFragment
    }
    utils: datoCmsGlobal(locale: $locale) {
      aboutAuthors: postAboutAuthorsLabel
      tableOfContentLabel: postTableOfContentLabel
      shareLabel: postShareLabel
      showPush: postShowPush
      headline: postPushHeadline
      button: postPushButton {
        ...buttonFragment
      }
    }
    relatedPosts: allDatoCmsPost(
      locale: $locale
      limit: 3
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { id: { ne: $id }, _allContentLocales: { elemMatch: { locale: { eq: $locale } } } }
    ) @skip(if: $hasCustomRelatedPosts) {
      nodes {
        ...postPreviewWithCategoriesFragment
      }
    }
  }
`

export default Post
